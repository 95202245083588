
import { extend, ValidationProvider } from 'vee-validate';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { integer, max, min, regex, required } from 'vee-validate/dist/rules';
import Environment from '@flashpointbv/solar-ui-environment';

extend('required', required);
extend('integer', integer);
extend('min', min);
extend('max', max);
extend('regex', regex);

@Component({
  name: 'checkout-address-form',
  components: {
    CheckoutAddressAutocomplete: require('./AddressAutocomplete.vue').default,
    ValidationProvider,
  },
})
export default class extends Vue {
  @Prop({ required: true }) private type: string;
  @Prop({ default: false }) private postcodeValidationEnabled: boolean;

  private addressAutocomplete: boolean = true;

  private inputAddress: object = {
    prefix: '',
    firstname: '',
    middlename: '',
    lastname: '',
    postcode: '',
    street: ['', '', ''],
    city: '',
    telephone: '',
    company: '',
    vat_id: '',
    country_code: Environment().get('checkout_default_country') ?? 'NL',
    extension_attributes: {
      coc_number: '',
    },
  };

  private get shippingAddress(): object {
    return this.$store.getters['CheckoutAddress/shippingAddress'];
  }

  private get shippingCountries(): Array<object> {
    return this.$store.getters['CheckoutShipping/shippingCountries'];
  }

  private get billingAddress(): object {
    return this.$store.getters['CheckoutAddress/billingAddress'];
  }

  @Watch('inputAddress', { deep: true })
  handler(nv): void {
    if (this.type === 'shipping') {
      this.$store.dispatch('CheckoutAddress/setShippingAddress', {
        address: nv,
      });
      return;
    }

    this.$store.dispatch('CheckoutAddress/setBillingAddress', {
      address: nv,
    });
  }

  private created(): void {
    if (this.type === 'shipping' && Object.keys(this.shippingAddress).length > 0) {
      this.inputAddress = Object.assign(this.inputAddress, this.shippingAddress);
    }

    if (this.type === 'billing' && Object.keys(this.billingAddress).length > 0) {
      this.inputAddress = Object.assign(this.inputAddress, this.billingAddress);
    }
  }
}
