
import {Component, Vue, Prop} from 'vue-property-decorator';

@Component({
  name: 'header-cart',
})
export default class extends Vue {
  @Prop() readonly label: string;
  @Prop() readonly quoteItemCount: number;

  private itemCount: number = 0;

  private created(): void {
    this.itemCount = this.quoteItemCount;
    window.addEventListener('CheckoutAddedProductToCartEvent', () => this.itemCount++);
  }
}
