
import { required } from 'vee-validate/dist/rules';
import { Component, Vue } from 'vue-property-decorator';
import { ValidationProvider, extend } from 'vee-validate';
import ServiceContainer from '@flashpointbv/solar-service-container';

extend('required', required);

@Component({
  name: 'checkout-shipping-detail',
  components: {
    CheckoutShippingMethodsDefault: require('./../shipping/methods/Default.vue').default,
    ValidationProvider,
  },
})
export default class extends Vue {
  private get loadingShippingMethods(): boolean {
    return this.$store.getters['CheckoutShipping/loadingShippingMethods'];
  }

  private get shippingMethods(): Array<object> {
    return this.$store.getters['CheckoutShipping/shippingMethods'];
  }

  private get selectedShippingMethod(): object | string {
    return Object.keys(this.$store.state.CheckoutShipping.shippingMethod || {}).length > 0 ? this.$store.state.CheckoutShipping.shippingMethod : '';
  }

  private getShippingMethodComponent(carrierCode) {
    switch (carrierCode) {
      default:
        return 'checkout-shipping-methods-default';
    }
  }

  private async created(): Promise<void> {
    await this.$store.dispatch('CheckoutShipping/collectShippingMethods');
  }
}
