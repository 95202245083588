
import {Component, Prop, Vue} from 'vue-property-decorator';
import Tablerate from './components/Tablerate.vue';

@Component({
  name: 'checkout-shipping-methods-default',
})
export default class extends Vue {
  @Prop({required: true}) private shippingMethod: object;
  @Prop() private selectedShippingMethod: object;

  private get isActiveMethod(): boolean {
    return (this.selectedShippingMethod || {})['carrier_code'] === this.shippingMethod['carrier_code'];
  }

  private async changeSelectedShippingMethod(method): Promise<void> {
    await this.$store.dispatch('CheckoutShipping/setShippingMethod', method);
  }
}
