
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'checkout-summary',
  components: {
    CheckoutSummaryPersonalAddress: require('./personal/address.vue').default,
  },
})
export default class extends Vue {
  private get customerEmail(): string {
    return this.$store.getters['CheckoutGlobal/customerEmail'] || '';
  }

  private get customerAddressInformation(): object {
    return this.$store.getters['CheckoutAddress/shippingAddress'];
  }

  private get cartTotals(): object {
    return this.$store.getters['CheckoutTotals/cartTotals'];
  }

  private get totalsAvailable(): boolean {
    return this.$store.getters['CheckoutTotals/totalsAvailable'];
  }

  private get cartItems(): object {
    return this.$store.getters['CheckoutTotals/cartItems'];
  }

  private gotoStep(step): void {
    this.$store.dispatch('CheckoutGlobal/gotoStep', step);
  }
}
