
import { Component, Vue } from 'vue-property-decorator';
import { extend, ValidationProvider } from 'vee-validate';
import { email, required } from 'vee-validate/dist/rules';
import { appLocalStorage } from '../../../stores/checkout/services';

extend('required', required);
extend('email', email);

@Component({
  name: 'checkout-address-login',
  components: {
    ValidationProvider,
  },
})
export default class extends Vue {
  private customerEmail: string = appLocalStorage.getItem('checkout-customer-email', null);
  private showLoginLink: boolean = false;

  private get showLoginForm(): boolean {
    return this.$store.getters['CheckoutGlobal/showLoginForm'];
  }

  private get getCustomerEmail(): string {
    return this.$store.getters['CheckoutGlobal/customerEmail'];
  }

  private get isLoggedIn(): object {
    return this.$store.getters['CheckoutGlobal/isLoggedIn'];
  }

  private async checkCustomerEmail() {
    if (!this.customerEmail) {
      return;
    }
    const { data } = await this.$store.dispatch('CheckoutGlobal/checkCustomerEmail', this.customerEmail);
    await this.$store.commit('CheckoutGlobal/SET_CUSTOMER_EMAIL', this.customerEmail);

    if (!data) {
      this.showLoginLink = true;
      return;
    }

    this.showLoginLink = false;
  }

  private created(): void {
    if (this.getCustomerEmail) {
      this.customerEmail = this.getCustomerEmail;
      this.checkCustomerEmail();
    }
  }
}
