export default {
    "back": "terug",
    "Sort by": "Sorteren op",
    "From": "Vanaf",
    "Add to cart": "In winkelwagen",
    "Popularity": "Meest populair",
    "Name (A-Z)": "Naam (A-Z)",
    "Name (Z-A)": "Naam (Z-A)",
    "Price (Ascending)": "Prijs (Laag-Hoog)",
    "Price (Descending)": "Prijs (Hoog-Laag)",
    "Clear all": "Wissen",
    "Apply": "Toepassen",
    "Failed to add to cart": "Kan niet worden toegevoegd aan winkelwagen",
    "Adding to cart": "Toevoegen aan winkelwagen",
    "Added to cart": "Toegevoegd aan winkelwagen",
    "Search": "Zoeken",
    "Product information": "Productinformatie",
    "Specifications": "Specificaties",
    "User manuals": "Handleidingen",
    "Cart": "Winkelwagen",
    "Subtotal": "Subtotaal",
    "Shipping & Handling": "Verzendkosten",
    "Grand Total": "Totaal",
    "Free": "Gratis",
    "Secure connection": "Beveiligde verbinding",
    "To checkout": "Naar bestellen",
    "Postal code": "Postcode",
    "House number": "Huisnummer",
    "House no.": "Huisnr.",
    "Addition": "Toevoeging",
    "Add.": "Toevoeging",
    "Click here to automatically retrieve your address": "Klik hier om automatisch je adres op te halen",
    "We have found the following address": "We hebben het volgende adres gevonden",
    "Invalid address. Are you sure it is correct?": "Ongeldig adres. Weet je zeker dat het correct is?",
    "Click here to enter the address manually.": "Klik hier om je adres handmatig in te voeren.",
    "Street": "Straat",
    "City": "Stad",
    "First name": "Voornaam",
    "Last name": "Achternaam",
    "Country": "Land",
    "Telephone": "Telefoonnummer",
    "Add new address": "Nieuw adres toevoegen",
    "Select your address": "Selecteer je adres",
    "Already have an account with us? Log in {here}": "Heb je al een account? Log {here} in",
    "here": "hier",
    "Don't want to login? Click {here}": "Bestellen zonder in te loggen? Klik {here}",
    "Email address": "E-mailadres",
    "You have an account. Would you like to {action}?": "Je hebt al een account. Wil je {action}?",
    "Login": "Inloggen",
    "Password": "Wachtwoord",
    "Log in": "Inloggen",
    "Forgot password": "Wachtwoord vergeten",
    "Shipping address": "Bezorgadres",
    "Sign up for our newsletter": "Schrijf je in voor onze nieuwsbrief",
    "Billing address is different from the address above": "Factuuradres is anders dan het bovenstaande adres",
    "Billing address": "Factuuradres",
    "Payment methods": "Betaalmethodes",
    "Something went wrong, the payment could not be completed. Please try again or choose a different payment method.": "Er is iets misgegaan, de betaling kon niet worden voltooid. Probeer het opnieuw of kies een andere betaalmethode.",
    "Please wait, loading payment methods": "Even geduld, betaalmethodes worden geladen",
    "Shipping methods": "Verzendmethodes",
    "Please wait, loading shipping methods": "Even geduld, verzendmethodes worden geladen",
    "Overview of your order": "Overzicht van je bestelling",
    "Change": "Wijzigen",
    "Your order will be delivered on": "Je bestelling wordt bezorgd op",
    "Details": "Gegevens",
    "Shipping": "Verzending",
    "Payment": "Betaling",
    "Back to cart": "Terug naar winkelwagen",
    "To shipping": "Naar verzending",
    "To payment": "Naar betaling",
    "Complete order": "Bestelling afronden",
    "Unable to place order, please try again later.": "Kan bestelling niet plaatsen, probeer het later opnieuw.",
    "Loading address...": "Adres wordt geladen...",
    "Without extra fees": "Zonder extra kosten",
    "Thank you for your purchase!": "Bedankt voor je aankoop!",
    "Your order has been successfully placed.": "Je bestelling is succesvol geplaatst.",
    "Your order number is: #{order}Id. You will receive an email confirming your order with all the details within 15 minutes. Also check your spam folder if you have not received a confirmation email after 15 minutes. If you have any questions about the status of your order, it is best to contact customer service.": "Je bestelnummer is: #{order}Id. Je ontvangt binnen 15 minuten een e-mail met daarin een bevestiging van je bestelling met alle details. Controleer ook je spamfolder als je na 15 minuten nog geen bevestigingsmail hebt ontvangen. Heb je vragen over de status van je bestelling? Neem dan contact op met onze klantenservice.",
    "Your ordered products": "Je bestelde producten",
    "Zip code or municipality": "Zoek op plaats",
    "Plan an appointment": "Afspraak inplannen",
    "Choose your country": "Kies je land",
    "No stores were found near the specified address.": "Er zijn geen winkels gevonden bij het opgegeven adres.",
    "Something went wrong while searching for stores. Try again later.": "Er is iets misgegaan bij het zoeken naar winkels. Probeer het later opnieuw.",
    "Search results for &ldquo;{term}&rdquo;": "Zoekresultaten voor &ldquo;{term}&rdquo;",
    "Categories": "Assortiment",
    "Price": "Prijs",
    "Shopping cart": "Winkelwagen",
    "You have no product(s) in your shopping cart.": "Je hebt geen product(en) in je winkelwagen.",
    "Click here to continue shopping.": "Klik hier om verder te winkelen.",
    "Recently viewed products": "Recent bekeken producten",
    "No products were found for &ldquo;{term}&rdquo;.": "Er zijn geen producten gevonden voor &ldquo;{term}&rdquo;.",
    "Stores": "Winkels",
    "View store": "Bekijk winkel",
    "Use my location": "Gebruik mijn locatie",
    "Show all articles": "Toon alle artikelen",
    "Your total": "Jouw totaal",
    "incl. TAX": "incl. BTW",
    "Save this configuration": "Deze configuratie opslaan",
    "Order now": "Bestel nu",
    "Yes": "Ja",
    "No": "Nee",
    "View details": "Bekijken",
    "Show more": "Meer tonen",
    "Show less": "Minder tonen",
    "The account login is incorrect or your account is temporarily disabled. Please wait and try again later.": "De account aanmelding is onjuist of uw account is tijdelijk uitgeschakeld. Wacht en probeer het later opnieuw.",
    "Blog categories": "Blog categorie\u00ebn",
    "Placed on": "Geplaatst op",
    "Share this post": "Deel dit bericht",
    "My profile": "Mijn profiel",
    "What are you looking for": "Waar ben je naar op zoek",
    "our advice": "ons advies",
    "Be inspired": "Laat je inspireren",
    "Follow us": "Volg ons",
    "Support level": "Ondersteuningsniveau",
    "No products were found in this category.": "Er zijn geen producten gevonden in deze categorie.",
    "Choose your {option}": "Kies je {option}",
    "This product is currently sold out": "Dit product is momenteel uitverkocht",
    "Choose an option": "Kies een optie",
    "Product has been added to your shopping cart": "Product is toegevoegd aan je winkelwagen",
    "Saving configuration...": "Configuratie opslaan...",
    "Configuration code ready!": "Vul je gegevens in!",
    "The configuration could not be saved, please try again later.": "De configuratie kon niet worden opgeslagen, probeer het later opnieuw.",
    "Your unique code!": "Jouw unieke code!",
    "Copy code": "Code kopi\u00ebren",
    "E-mail code": "Code mailen",
    "Code copied!": "Code gekopie\u00ebrd!",
    "Code sent!": "Code verstuurd!",
    "Something went wrong with sending the code, please try again.": "Er is iets misgegaan met het versturen van de code, probeer het opnieuw.",
    "This product is available in the following options": "Dit product is beschikbaar in de volgende uitvoeringen",
    "Show address": "Adres tonen",
    "We couldn't retrieve your location, please try again or use the search function.": "We konden je locatie niet ophalen, probeer het opnieuw of gebruik de zoekfunctie.",
    "Find your Ergosleep\u00ae store": "Vind je Ergosleep\u00ae winkel",
    "Send": "Versturen",
    "Configure this bed": "Configureer jouw bed",
    "Your unique bed": "Jouw unieke bed",
    "Newest": "Nieuwste",
    "View configuration": "Bekijk configuratie",
    "Productverrijking": "Productspecificaties",
    "Read more": "Lees meer",
    "Find a store near you.": "Vind een winkel in jouw buurt.",
    "Filter by country": "Filter op land",
    "All countries": "Alle landen",
    "Filter": "Filter",
    "Opening hours": "Openingstijden",
    "Regular opening hours": "Reguliere openingstijden",
    "Monday": "Maandag",
    "Tuesday": "Dinsdag",
    "Wednesday": "Woensdag",
    "Thursday": "Donderdag",
    "Friday": "Vrijdag",
    "Saturday": "Zaterdag",
    "Sunday": "Zondag",
    "Closed": "Gesloten",
    "Open by appointment": "Open op afspraak",
    "Address details": "Adresgegevens",
    "Special holidays": "Speciale feestdagen",
    "Measure your SLEEP-DNA\u00ae": "Laat je SLEEP-DNA\u00ae meten",
    "Direction information": "Route omschrijving",
    "Accessibility": "Bereikbaarheid",
    "Make an appointment": "Maak een afspraak",
    "Fields marked with an * are required.": "*Verplichte velden",
    "Submit": "Verzenden",
    "Continue in my current language.": "Doorgaan in mijn huidige taal.",
    "Errors in pricing, printing and typesetting are reserved": "Prijs- druk- en zetfouten voorbehouden",
    "Personalise": "Personaliseer"
}