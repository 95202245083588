
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'checkout-address-billing',
  components: {
    CheckoutAddressForm: require('./input/AddressForm.vue').default,
    CheckoutAddressSelect: require('./input/AddressSelect.vue').default,
  },
})
export default class extends Vue {
  @Prop({ required: false }) private addresses: Array<object>;

  private manualForm: boolean = false;
  private selectedAddress: object = {};

  private get billingAddress(): object {
    return this.$store.state['CheckoutAddress']['billingAddress'];
  }

  private get showForm(): boolean {
    return this.billingAddress.hasOwnProperty('newAddress') || this.manualForm || !this.addresses.length;
  }

  @Watch('selectedAddress')
  private handler(nv, ov): void {
    if (nv === ov) {
      return;
    }

    this.$store.dispatch('CheckoutAddress/setBillingAddress', {
      address: nv,
    });
  }
}
