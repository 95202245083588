
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { appSessionStorage } from '../../../stores/checkout/services';

@Component({
  name: 'checkout-address-detail',
  components: {
    CheckoutLogin: require('./../address/CheckoutLogin.vue').default,
    CheckoutAddressBilling: require('./../address/BillingAddress.vue').default,
    CheckoutAddressShipping: require('./../address/ShippingAddress.vue').default,
  },
})
export default class extends Vue {
  @Prop({ required: false, default: false }) private isLoggedIn: boolean;
  @Prop({ required: false, default: [] }) private addresses: Array<object>;
  private checkboxChecked: boolean = false;
  protected newsletter: boolean = appSessionStorage.getItem('checkout-newsletter-subscription', true);

  created() {
    // if (this.newsletter) {
    //   this.$store.dispatch('CheckoutGlobal/newsletterSubscribe', this.newsletter);
    // }
  }

  protected get billingIsSameAsShipping(): boolean {
    this.checkboxChecked = !this.$store.getters['CheckoutAddress/billingIsSameAsShipping'];
    return this.$store.getters['CheckoutAddress/billingIsSameAsShipping'];
  }

  protected toggleBillingIsSameAsShipping(): void {
    this.$store.dispatch('CheckoutAddress/toggleBillingIsSameAsShipping');
  }

  @Watch('newsletter')
  protected handleNewsletter(nv) {
    // appSessionStorage.setItem('checkout-newsletter-subscription', nv);
    // this.$store.dispatch('CheckoutGlobal/newsletterSubscribe', nv);
  }
}
