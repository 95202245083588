
import {ValidationObserver} from 'vee-validate';
import {Component, Prop, Vue} from 'vue-property-decorator';
import VueI18n from 'vue-i18n';
import TranslateResult = VueI18n.TranslateResult;

@Component({
  name: 'checkout-checkout',
  components: {
    ValidationObserver,
    CheckoutSummary: require('./summary/Summary.vue').default,
    CheckoutAddressDetail: require('./step/AddressDetail.vue').default,
    CheckoutPaymentDetail: require('./step/PaymentDetail.vue').default,
    CheckoutShippingDetail: require('./step/ShippingDetail.vue').default,
  },
})
export default class extends Vue {
  $refs!: {
    form: InstanceType<typeof ValidationObserver>;
  };
  @Prop({required: false}) private mergeMessage: string;
  @Prop({required: true}) private quote: object;
  @Prop({default: false}) private isLoggedIn: boolean;
  @Prop({default: null}) private customerEmail: string;
  @Prop({default: []}) private addresses: Array<object>;
  @Prop({default: null}) private countries: Array<object>;
  @Prop({default: null}) private errorMessage: string | null;

  private get loadingShippingMethods(): boolean {
    return this.$store.getters['CheckoutShipping/loadingShippingMethods'];
  }

  private get loadingPaymentData(): boolean {
    return this.$store.getters['CheckoutPayment/loadingPaymentData'];
  }

  protected loadingCheckout: boolean = false;
  protected checkoutErrorMessage: TranslateResult = '';

  private get showLoginForm(): boolean {
    return this.$store.getters['CheckoutGlobal/showLoginForm'];
  }

  private get currentStep(): number {
    return this.$store.getters['CheckoutGlobal/currentStep'];
  }

  private async mounted(): Promise<void> {
    await this.$store.dispatch('CheckoutShipping/setShippingCountries', this.countries);
    await this.$store.dispatch('CheckoutTotals/fetchCartTotals');

    if (this.isLoggedIn && this.customerEmail) {
      this.$store.commit('CheckoutGlobal/SET_CUSTOMER_EMAIL', this.customerEmail);
    }
  }

  private goToStep(step): void {
    if (step === this.currentStep || this.showLoginForm) {
      return;
    }

    if (step - this.currentStep > 1) {
      step = this.currentStep + 1;
    }

    this.$refs.form.validate().then((result) => {
      if (result || step < this.currentStep) {
        this.$store.dispatch('CheckoutGlobal/gotoStep', step);
        window.scrollTo(0, 0);
      } else {
        if (this.currentStep === 1) {
          const failedInputElements = document.querySelectorAll('input.failed');
          Array.from(failedInputElements).forEach((el, index) => {
            if (index === 0) {
              el.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'start',
              });
            }
            el.classList.add('--shake');
          });
        }
      }
    });
  }

  private async placeOrder() {
    try {
      this.loadingCheckout = true;
      const {data} = await this.$store.dispatch('CheckoutPayment/placeOrder');

      if (data.hasOwnProperty('RequiredAction')) {
        window.location.href = data['RequiredAction']['RedirectURL'];
        return;
      }

      window.location.replace('/checkout/onepage/success');
    } catch (err) {
      try {
        const response = JSON.parse(err.request.response);
        this.checkoutErrorMessage = '' !== response['exception']['message'] ? response['exception']['message'] : this.$t('Unable to place order, please try again later.');
        window.scrollTo(0, 0);
      } catch (e) {
        this.checkoutErrorMessage = this.$t('Unable to place order, please try again later.');
        window.scrollTo(0, 0);
      } finally {
        this.loadingCheckout = false;
      }
    }
  }
}
