const body = document.body;

('.main__navigation>ul').dropDown(el => {
  const dropdown = el.getElementsByClassName('--dropdown');
  if (dropdown.length) {
    dropdown[0].style.display = 'block';
  }
}, el => {
  const dropdown = el.getElementsByClassName('--dropdown');
  if (dropdown.length) {
    dropdown[0].style.display = 'none';
  }
}, 200);

window.addEventListener('load', () => {
  const offCanvasElement = document.getElementById('offCanvas');
  const menuItems = offCanvasElement.querySelectorAll('nav li');
  menuItems.forEach((menuItem): void => {
    if (menuItem.classList.contains('--has-sub')) {
      menuItem.querySelector('a').addEventListener('click', (event): void => {
        event.preventDefault();
        menuItem.classList.toggle('--active');
      });
    }
  });

  const inputElements = document.querySelectorAll('input[data-placeholder], textarea[data-placeholder]');
  inputElements.forEach((inputElement): void => {
    createLabelElement(inputElement as HTMLInputElement);

    inputElement.addEventListener('focus', (): void => {
      placeHolderAnimation(inputElement as HTMLInputElement);
    });
    inputElement.addEventListener('blur', (): void => {
      placeHolderAnimation(inputElement as HTMLInputElement);
    });
    inputElement.addEventListener('input', (): void => {
      placeHolderAnimation(inputElement as HTMLInputElement);
    });
  });

  function placeHolderAnimation(inputElement: HTMLInputElement | HTMLTextAreaElement): void {
    if (inputElement.value.length > 0) {
      inputElement.parentElement.classList.add('--placeholder');
    } else {
      inputElement.parentElement.classList.remove('--placeholder');
    }
  }

  function createLabelElement(inputElement: HTMLInputElement | HTMLTextAreaElement): void {
    const fieldHolder = document.createElement('div');
    fieldHolder.classList.add('field__holder');
    inputElement.parentNode.insertBefore(fieldHolder, inputElement);

    const labelElement = document.createElement('label');
    labelElement.innerHTML = inputElement.placeholder;
    labelElement.setAttribute('for', inputElement.id);

    inputElement.placeholder = '';

    fieldHolder.appendChild(inputElement);
    fieldHolder.appendChild(labelElement);
  }

  const currentScrollPosition = document.documentElement.scrollTop || document.body.scrollTop;
  if (currentScrollPosition > 0) {
    const stickyHeader = document.querySelector('header .sticky');
    stickyHeader.classList.add('is-stuck');
  }

  const searchButton = document.getElementById('searchButton');
  if (searchButton) {
    searchButton.addEventListener('click', e => {
      const offScreenSearchElement = document.querySelector('[data-role=offscreen-search]');
      if (offScreenSearchElement) {
        const htmlElement = (offScreenSearchElement as HTMLElement);
        htmlElement.style.display = 'flex';
        body.classList.add('disable-scroll');
        htmlElement.getElementsByTagName('input')[0].focus();

        setTimeout(() => {
          htmlElement.style.opacity = '1';
        }, 100);

        let closePopup = function (e) {
          if (e.target.classList.contains('search__modal--overlay') || e.target.classList.contains('--close') || e.target.classList.contains('grid-container') || e.which === 27) {
            body.classList.remove('disable-scroll');
            htmlElement.style.opacity = '0';
            setTimeout(() => {
              htmlElement.style.display = 'none';
            }, 100);
          }
        };

        window.addEventListener('click', closePopup, false);
        window.addEventListener('keyup', closePopup, false);
      }
    });
  }
});

import SimpleLightbox from 'simplelightbox';

window.addEventListener('load', () => {
  let gallery = new SimpleLightbox('.lightbox__container a', {
    maxZoom: 5,
    loop: false,
    overlayOpacity: 0.45,
    navText: ['', ''],
    closeText: '',
    showCounter: false,
  });

  gallery.on('changed.simplelightbox', function (e) {
    const mediaGallery = document.getElementById('mediaGallery');
    const blazeSlider = mediaGallery['blazeSlider'];

    const thumbnailGallery = document.getElementById('thumbnailGallery');
    const blazeSliderThumbnail = thumbnailGallery['blazeSlider'];

    const index = e.target.getAttribute('data-index');

    blazeSlider.slideTo(index);
    blazeSliderThumbnail.slideTo(index);
  });
});
