
import {Component, Vue, Prop, Ref} from 'vue-property-decorator';
import {ValidationProvider, extend} from 'vee-validate';
import {required} from 'vee-validate/dist/rules';

extend('required', required);

@Component({
  name: 'checkout-payment-methods-default',
  components: {
    ValidationProvider,
  },
})
export default class extends Vue {
  @Prop({required: true}) private paymentMethod: object;
  @Prop() private selectedPaymentMethod: object;

  @Ref('order') readonly order!: HTMLButtonElement;

  private get isActiveMethod(): boolean {
    return this.selectedPaymentMethod.hasOwnProperty('code') && this.selectedPaymentMethod['code'] === this.paymentMethod['code'];
  }

  private changeSelectedPaymentMethod(): void {
    this.$store.commit('CheckoutPayment/CLEAR_PAYMENT_DETAILS');
    this.$store.dispatch('CheckoutPayment/setSelectedPaymentMethod', this.paymentMethod);
  }
}
