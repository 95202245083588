
import {Component, Vue} from 'vue-property-decorator';

@Component({
  name: 'search-form'
})

export default class extends Vue {
  private inputQuery: string = '';
  protected autocompleteData: Array<object> = [];
  private _throttle: number;
  private autocompleteLoading: boolean = false;
  private autocompleteRemove: boolean = false;
  private showAutocomplete: boolean = false;

  private async fetchAutocompleteData(): Promise<void> {
    clearTimeout(this._throttle);
    this._throttle = window.setTimeout(async () => {
      this.autocompleteRemove = this.inputQuery.length > 0;
      if (this.inputQuery.length > 3) {
        this.autocompleteLoading = true;
        try {
          const {data} = await this.$solarClient.get('/api/catalog/search/product/list', {
            params: {
              query: this.inputQuery
            }
          });

          this.autocompleteData = data.items;
          this.showAutocomplete = true;
        } catch (e) {
          console.error(e);
          this.showAutocomplete = false;
        } finally {
          this.autocompleteLoading = false;
        }
      }
    }, 500);
  }

  private clearSearchInput(): void {
    this.inputQuery = '';
    this.showAutocomplete = false;
    this.autocompleteRemove = false;
  }

  private get locale(): string {
    return this.$config.get('locale');
  }

  private imageSrc(product): string {
    return `${this.$solarCdnBaseUrl}/cdn/resize-extended/rs:fill/w:70/h:48/extend:true/background:255:255:255/path/media/catalog/product${product['image']}`;
  }
}
