export class AppStorage {
  protected storage: Storage;

  constructor(storage) {
    this.storage = storage || window.localStorage;

    if (!this.isSupported()) {
      throw new Error('Storage is not supported by browser!');
    }
  }

  setItem(key, value) {
    if ('undefined' !== typeof value) {
      this.storage.setItem(key, JSON.stringify(value));
    }
  }

  getItem(key, defaultValue) {
    const value = this.storage.getItem(key);

    if ('undefined' !== typeof value) {
      if (null === value) {
        return 'undefined' !== typeof defaultValue ? defaultValue : null;
      }
      return JSON.parse(this.storage.getItem(key));
    }

    this.storage.removeItem(key);
    return null;
  }

  removeItem(key) {
    this.storage.removeItem(key);
  }

  clear() {
    this.storage.clear();
  }

  isSupported() {
    let supported = true;

    if (!this.storage) {
      supported = false;
    }

    return supported;
  }
}

const appLocalStorage: AppStorage = new AppStorage(window.localStorage);
const appSessionStorage: AppStorage = new AppStorage(window.sessionStorage);

export { appLocalStorage, appSessionStorage };
