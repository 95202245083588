
import {extend, ValidationProvider} from 'vee-validate';
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {integer, max, min, regex, required} from 'vee-validate/dist/rules';
import {appLocalStorage} from '../../../../shared/services';

extend('required', required);
extend('integer', integer);
extend('min', min);
extend('max', max);
extend('regex', regex);

@Component({
  name: 'checkout-address-autocomplete',
  components: {
    ValidationProvider,
  },
})
export default class extends Vue {
  @Prop() readonly type: string;

  protected autoComplete: object = {
    postcode: null,
    houseNr: null,
    houseNrAdd: null,
  };

  private isLoading: boolean = false;
  private foundLocation: boolean = false;
  private resolvedAddress: object = {};
  private resolveManually: boolean = false;

  private async postcodeLookup(): Promise<void> {
    if (null !== this.autoComplete['postcode'] && null !== this.autoComplete['houseNr']) {
      this.isLoading = true;
      this.foundLocation = false;
      try {
        this.resolvedAddress = await this.$store.dispatch('CheckoutAddress/postcodeLookup', this.autoComplete);
        this.foundLocation = true;

        this.$parent['inputAddress']['street'] = [this.resolvedAddress['street'], this.resolvedAddress['house_nr'], this.resolvedAddress['houseNumberAddition']];
        this.$parent['inputAddress']['city'] = this.resolvedAddress['place'];
        this.$parent['inputAddress']['postcode'] = this.resolvedAddress['zipcode'];
      } catch (e) {
        this.resolvedAddress['error'] = true;
        this.foundLocation = false;
        this.$parent['inputAddress']['street'] = ['', this.$parent['inputAddress']['street'][1], this.$parent['inputAddress']['street'][2]];
        this.$parent['inputAddress']['city'] = '';
      } finally {
        this.isLoading = false;
      }
    }
  }

  private resolvedAddressManually(): void {
    this.resolveManually = true;
  }

  private resolveAddress(): void {
    this.postcodeLookup();
    this.resolveManually = false;
  }

  private created(): void {
    if (appLocalStorage.hasItem(`checkout-autocomplete-${this.type}-address`)) {
      this.autoComplete = appLocalStorage.getItem(`checkout-autocomplete-${this.type}-address`);
    }

    if (null !== this.autoComplete['postcode'] && null !== this.autoComplete['houseNr']) {
      this.postcodeLookup();
    }
  }

  @Watch('autoComplete', {deep: true})
  handle(nv, ov) {
    appLocalStorage.setItem(`checkout-autocomplete-${this.type}-address`, nv);
  }
}
