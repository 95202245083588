export default {
    "back": "back",
    "Sort by": "Sort by",
    "From": "From",
    "Add to cart": "Add to cart",
    "Popularity": "Popularity",
    "Name (A-Z)": "Name (A-Z)",
    "Name (Z-A)": "Name (Z-A)",
    "Price (Ascending)": "Price (Ascending)",
    "Price (Descending)": "Price (Descending)",
    "Clear all": "Clear all",
    "Apply": "Apply",
    "Failed to add to cart": "Failed to add to cart",
    "Adding to cart": "Adding to cart",
    "Added to cart": "Added to cart",
    "Search": "Search",
    "Product information": "Product information",
    "Specifications": "Specifications",
    "User manuals": "User manuals",
    "Cart": "Cart",
    "Subtotal": "Subtotal",
    "Shipping & Handling": "Shipping & Handling",
    "Grand Total": "Grand Total",
    "Free": "Free",
    "Secure connection": "Secure connection",
    "To checkout": "To checkout",
    "Postal code": "Postal code",
    "House number": "House number",
    "House no.": "House no.",
    "Addition": "Addition",
    "Add.": "Add.",
    "Click here to automatically retrieve your address": "Click here to automatically retrieve your address",
    "We have found the following address": "We have found the following address",
    "Invalid address. Are you sure it is correct?": "Invalid address. Are you sure it is correct?",
    "Click here to enter the address manually.": "Click here to enter the address manually.",
    "Street": "Street",
    "City": "City",
    "First name": "First name",
    "Last name": "Last name",
    "Country": "Country",
    "Telephone": "Telephone",
    "Add new address": "Add new address",
    "Select your address": "Select your address",
    "Already have an account with us? Log in {here}": "Already have an account with us? Log in {here}",
    "here": "here",
    "Don't want to login? Click {here}": "Don't want to login? Click {here}",
    "Email address": "Email address",
    "You have an account. Would you like to {action}?": "You have an account. Would you like to {action}?",
    "Login": "Login",
    "Password": "Password",
    "Log in": "Log in",
    "Forgot password": "Forgot password",
    "Shipping address": "Shipping address",
    "Sign up for our newsletter": "Sign up for our newsletter",
    "Billing address is different from the address above": "Billing address is different from the address above",
    "Billing address": "Billing address",
    "Payment methods": "Payment methods",
    "Something went wrong, the payment could not be completed. Please try again or choose a different payment method.": "Something went wrong, the payment could not be completed. Please try again or choose a different payment method.",
    "Please wait, loading payment methods": "Please wait, loading payment methods",
    "Shipping methods": "Shipping methods",
    "Please wait, loading shipping methods": "Please wait, loading shipping methods",
    "Overview of your order": "Overview of your order",
    "Change": "Change",
    "Your order will be delivered on": "Your order will be delivered on",
    "Details": "Details",
    "Shipping": "Shipping",
    "Payment": "Payment",
    "Back to cart": "Back to cart",
    "To shipping": "To shipping",
    "To payment": "To payment",
    "Complete order": "Complete order",
    "Unable to place order, please try again later.": "Unable to place order, please try again later.",
    "Loading address...": "Loading address...",
    "Without extra fees": "Without extra fees",
    "Thank you for your purchase!": "Thank you for your purchase!",
    "Your order has been successfully placed.": "Your order has been successfully placed.",
    "Your order number is: #{order}Id. You will receive an email confirming your order with all the details within 15 minutes. Also check your spam folder if you have not received a confirmation email after 15 minutes. If you have any questions about the status of your order, it is best to contact customer service.": "Your order number is: #{order}Id. You will receive an email confirming your order with all the details within 15 minutes. Also check your spam folder if you have not received a confirmation email after 15 minutes. If you have any questions about the status of your order, it is best to contact customer service.",
    "Your ordered products": "Your ordered products",
    "Zip code or municipality": "Search on location",
    "Plan an appointment": "Plan an appointment",
    "Choose your country": "Choose your country",
    "No stores were found near the specified address.": "No stores were found near the specified address.",
    "Something went wrong while searching for stores. Try again later.": "Something went wrong while searching for stores. Try again later.",
    "Search results for &ldquo;{term}&rdquo;": "Search results for &ldquo;{term}&rdquo;",
    "Categories": "Categories",
    "Price": "Price",
    "Shopping cart": "Shopping cart",
    "You have no product(s) in your shopping cart.": "You have no product(s) in your shopping cart.",
    "Click here to continue shopping.": "Click here to continue shopping.",
    "Recently viewed products": "Recently viewed products",
    "No products were found for &ldquo;{term}&rdquo;.": "No products were found for &ldquo;{term}&rdquo;.",
    "Stores": "Stores",
    "View store": "View store",
    "Use my location": "Use my location",
    "Show all articles": "Show all articles",
    "Your total": "Your total",
    "incl. TAX": "incl. TAX",
    "Save this configuration": "Save this configuration",
    "Order now": "Order now",
    "Yes": "Yes",
    "No": "No",
    "Copy": "Copy",
    "Copied": "Copied",
    "View details": "View details",
    "Show more": "Show more",
    "Show less": "Show less",
    "The account login is incorrect or your account is temporarily disabled. Please wait and try again later.": "The account login is incorrect or your account is temporarily disabled. Please wait and try again later.",
    "Blog categories": "Blog categories",
    "Placed on": "Placed on",
    "Share this post": "Share this post",
    "My profile": "My profile",
    "What are you looking for": "What are you looking for",
    "ons advies": "ons advies",
    "Be inspired": "Be inspired",
    "Follow us": "Follow us",
    "Support level": "Support level",
    "No products were found in this category.": "No products were found in this category.",
    "Choose your {option}": "Choose your {option}",
    "This product is currently sold out": "This product is currently sold out",
    "Choose an option": "Choose an option",
    "Product has been added to your shopping cart": "Product has been added to your shopping cart",
    "Saving configuration...": "Saving configuration...",
    "Configuration code ready!": "Configuration code ready!",
    "The configuration could not be saved, please try again later.": "The configuration could not be saved, please try again later.",
    "Your unique code!": "Your unique code!",
    "Copy code": "Copy code",
    "E-mail code": "E-mail code",
    "Code copied!": "Code copied!",
    "Code sent!": "Code sent!",
    "Something went wrong with sending the code, please try again.": "Something went wrong with sending the code, please try again.",
    "This product is available in the following options:": "This product is available in the following options:",
    "Show address": "Show address",
    "We couldn't retrieve your location, please try again or use the search function.": "We couldn't retrieve your location, please try again or use the search function.",
    "Find your Ergosleep\u00ae store": "Find your Ergosleep\u00ae store",
    "Send": "Send",
    "Configure this bed": "Create your perfect bed",
    "Your unique bed": "Your unique bed",
    "Newest": "Newest",
    "View configuration": "View configuration",
    "Productverrijking": "Product specifications",
    "Read more": "Read more",
    "Find a store near you.": "Find a store near you.",
    "Filter by country": "Filter by country",
    "All countries": "All countries",
    "Filter": "Filter",
    "Opening hours": "Opening hours",
    "Regular opening hours": "Regular opening hours",
    "Monday": "Monday",
    "Tuesday": "Tuesday",
    "Wednesday": "Wednesday",
    "Thursday": "Thursday",
    "Friday": "Friday",
    "Saturday": "Saturday",
    "Sunday": "Sunday",
    "Closed": "Closed",
    "Open by appointment": "Open by appointment",
    "Address details": "Address details",
    "Special holidays": "Special holidays",
    "Measure your SLEEP-DNA\u00ae": "Measure your SLEEP-DNA\u00ae",
    "Direction information": "Direction information",
    "Accessibility": "Accessibility",
    "Make an appointment": "Make an appointment",
    "Continue in my current language.": "Continue in my current language.",
    "Errors in pricing, printing and typesetting are reserved": "Errors in pricing, printing and typesetting are reserved",
    "Personalise": "Personalise"
}