const initialState = () => {
  return {};
};

const getters = {};

const mutations = {};

const actions = {
  async addProductToQuote({ state, commit, dispatch }, productPayload) {
    const { data } = await this.$solarClient.post(
      '/api/checkout/quote/addProductToQuote',
      {
        cartItem: productPayload,
      },
      { timeout: 25000 }
    );

    window.dispatchEvent(new CustomEvent('CheckoutAddedProductToCartEvent'));
  },
};

const state = initialState();

const CheckoutQuote = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default CheckoutQuote;
